import * as React from "react"
import Nav from "../component/nav"
import bg1 from "./../images/bg_1.png";
import "./../styles/global.css"
import { Link } from "gatsby";
import FadeIn from 'react-fade-in';

// markup
const IndexPage = () => {
  return (
    <div class="container mx-auto">
      <Nav />
      <FadeIn>
        <div class="flex">
            <div class="px-10 py-10 w-100 laptop:py-20 laptop:w-1/2 desktop:py-36">
              <p class="text text-2xl py-4 desktop:text-3xl">Hello! I'm</p>
              <h1 class="text text-4xl font-bold py-1 laptop:text-5xl desktop:text-6xl">Alex Kem</h1>
              <h1 class="text-4xl text-yellow font-bold py-1 laptop:text-5xl desktop:text-6xl">Software Engineer</h1>
              <p class="text text-md leading-7 w-2/3 py-4 laptop:w-3/4">
                I'm a full-time CS student at Cal State University of Long Beach. I enjoy building interactive applications on the web. Click the button below to see my work. 
              </p>
              <Link to="projects">
                <button class="btn">
                  View Projects
                </button>
              </Link>
            </div>
            <img src={bg1} alt="a pic" class="hidden laptop:py-20 laptop:block laptop:w-1/2 laptop:h-1/2"></img>
        </div>
      </FadeIn>
    </div> 
  )
}

export default IndexPage
